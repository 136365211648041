
.t-container {
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  padding: 20px;
  padding-top: 4rem;
  background-color: #242424;
  border-radius: 3% 3% 0 0;
}

/* DESKTOP */
@media screen and (min-width: 768px) {
  .image-container {
    width: 400px;
    padding: 40px 10px;
    align-items: center;
    justify-content: center;
  }
  
  .image-container > img {
    align-items: center;
    position: relative;
    width: 100%;
    height: 80%;
    z-index: 0;  
    filter: brightness(86%);
    
  }
  .text-container {
    width: 60%;
    padding: 0 10px;
  }
  
  .text-container > p {
    margin: 0; /* Remove default margin from paragraphs */
    font-size: 16px;
    font-family: 'Lucida Sans Unicode', sans-serif;
    color: antiquewhite;
    letter-spacing: 4px;
  
  }

  .text-container > h2 {
    font-family: 'Tomorrow', sans-serif;
    color: antiquewhite;
    text-transform: uppercase;
    font-size: 39px;
    letter-spacing: 2.5px;
    padding-bottom: 15px;
  }

}



/* TABLET, PHONE */
@media screen and (max-width: 768px) {
  .t-container {
    display:flex;
    flex-flow: column;
    align-content: center;
  }

  .text-container  > p {
    font-size: 12px;
    width: 100%;
    margin: 0; /* Remove default margin from paragraphs */
    font-family: 'Lucida Sans Unicode', sans-serif;
    color: antiquewhite;
    letter-spacing: 2px;
  }
  .text-container > h2 {
    font-family: 'Tomorrow', sans-serif;
    color: antiquewhite;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 2.5px;
    padding-bottom: 15px;
  }

  .text-container {
    display: block;
    width: 100%;
    text-align: center;
  }

  .image-container {
    display: flex;
    width: 350px;
    padding: 40px 0px;
    align-items: center;
    justify-content: center;
  }
  
  .image-container > img {
    align-items: center;
    position: relative;
    width: 80%;
    z-index: 0;  
    filter: brightness(86%);
    
  }

}


