.action-outer-wrapper {
  padding: 5% 0 5% 0;
  display: flex;
  justify-content: center;
  background-color: #242424;
  overflow-y: hidden;
}

.action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.action__item__link {
  flex: 1;
  /* Each item takes up equal space in the row */
  margin-right: 5px;
  /* Add margin between items */
  text-decoration: none;
  text-align: center;

}

.action__item__pic-wrap>img:hover {
  transform: scale(1.2);
}

.action__item__pic-wrap {
  position: relative;
  overflow: hidden;
}

.action__item__img {
  z-index: 1;
  position: relative;
  width: 15vw;
  height: 30vh;
  z-index: 0;
  filter: brightness(86%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 5px 5px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  transition: all .3s ease;
}

.action__item__info>h4 {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: 3px;
  color: antiquewhite;
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (max-width: 820px) {
  .action__item__info>h4 {
    font-size: 12px;
    line-height: 2.25rem;
  }

  .action__item__img {
    width: 35vw;
    height: 20vh;
  }

  .action-wrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-items: center;
    width: 85%;
    overflow-y: hidden;
  }
}