.pdf-button {
  padding: 8px 60px;
  border-color: #676861;
  color: #676861;
  font-family: 'Tomorrow', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  background: none;
  border: 1.5px solid #676861;
  cursor: pointer;
}

.pdf-button:hover {
  transition: all 0.3s ease-out;
  background: #676861;
  color: white;
}

.pdf-modal {
  position: fixed;
  background: #242424;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 1000;
  top: 5%;
  left:35%;
  overflow: hidden;
}

.pdf-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.modal-content{
  justify-content: center;
  align-items: center;
}

.close-button {
  background-color: #676861;
  color: white;
  border: none;
  border-radius: 50%; /* This ensures the button is circular */
  width: 40px; /* Adjust the width */
  height: 40px; /* Adjust the height */
  cursor: pointer;
  position: absolute;
  top: 10px; 
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none; /* Ensure no shadow causes a square look */
  padding: 0; /* Remove any padding that might affect shape */
}

.image-iframe {
  padding: 5vh ;
  width: auto;
  height: 100vh;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {

  .pdf-button {
    padding: 8px 20px; /* Adjust padding for smaller screens */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .pdf-modal {
    top: 10%;
    max-width: 90%; /* Increase width for mobile */
    max-height: 90%; /* Increase height for mobile */
    padding: 10px; /* Reduce padding for mobile */
    top: 5%;
    left:6%;
  }

  .image-iframe {
    height: auto; /* Adjust height for mobile screens */
    width: 90%;
    padding:2vh;
    top:20%;
  }

  .close-button {
    width: 45px;
    height: 45px;
    font-size: 22px;
    top: 5px;
    right: 5px;
  }
}
