.container-prices{
    display: flex;
    flex-direction:row;
    justify-content: center;
    background-color: #242424;
}

.container-prices >img{

    padding-top:5vh;    
    padding-right: 10vh;
    align-items: center;
    position: relative;
    width: 30%;
    z-index: 0;  
    filter: brightness(86%);
}
.container-prices > .container-pricelist{
    padding-top:5vh;
    width: 50%;
    padding-left: 10vh;
    background-color: #242424;
    font-family: 'Tomorrow', sans-serif;
    font-size: 18px;
    color: #e9e9e9;
}

.container-pricelist > h2{
    color: antiquewhite;
    padding-bottom: 2vh;
    text-transform: uppercase;
    font-size: 30px;
     }

.container-pricelist > h3{
    padding: 1vh 0 2vh;
    padding-left: 2vh;
}

ul{
    list-style-type: none;
    padding-left: 2vh;
    padding-bottom: 2vh;
    font-size: 16px;
}
.angebot-btns{
    background-color: #242424;
    padding: 10vh;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 820px) {

    .container-prices{
        padding-top: 7vh;
        display: flex;
        flex-direction:column;
        align-items: center;
    }

    .container-prices >img{
        display: flex;
        width: 100vh;
        height: 30vh;  
        padding:0;
        display: none;
    }

    .container-prices > .container-pricelist{
      padding: 2vh;
      width: 100%;
    }
  
    .container-pricelist > h2{
      padding-bottom: 2vh;
      text-transform: uppercase;
      font-size: 23px;
    }

    .container-pricelist > ul{        
        font-size: 15px;
      }

      .container-pricelist > h3{        
        font-size: 19px;
      }

    .container-prices >img{
        align-items: center;
        position: relative;
        width: 50%;
        height: 80%;
        z-index: 0;  
        filter: brightness(86%);
    }
 
    .container-pricelist > hr{
        width: 100%;
    }
    
}