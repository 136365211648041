.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
  }
  
  .whatsapp-icon svg {
    width: 40px;
    height: 40px;
  }
  
  @media screen and (max-width: 768px) {
    .whatsapp-icon {
      bottom: 15px;
      right: 15px;
      padding: 10px;
    }
  
    .whatsapp-icon svg {
      width: 30px;
      height: 30px;
    }
  }
  